import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IReturn {
  isAuthenticated: boolean;
  isFetched: boolean;
  profile: Types.IEntity.Profile;
  company: Types.IEntity.Company;
  methods: {
    logout: () => void;
  }
}

const useAuth = (): IReturn => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector<Store.Types.IState, boolean>(state => state.auth.isAuthenticated);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.auth.isFetched);
  const profile = useSelector<Store.Types.IState, Types.IEntity.Profile>(state => state.auth.profile);
  const company = useSelector<Store.Types.IState, Types.IEntity.Company>(state => state.auth.company);

  const logout = () => {
    dispatch(Actions.Logout.request());
  };

  return { isAuthenticated, isFetched, profile, company, methods: { logout } };
};

export default useAuth;