import React, { useState } from 'react';

import Context, { initialState } from './context';
import * as Types from '../types';

interface IProps extends Types.IContext.Props {
  children: React.ReactNode;
}

const Provider: React.FC<IProps> = ({ applicationId, registerId, serial, amount, children }) => {
  const [state, setState] = useState({ ...initialState, applicationId, registerId, serial, amount });

  const setPhone = (phone: string) => {
    setState((state) => ({ ...state, phone }));
  };

  const setValues = (values: Partial<Types.IContext.Values>) => {
    setState((state) => ({ ...state, values: { ...state.values, values } }));
  };

  const value = {
    state,
    methods: {
      setPhone,
      setValues
    }
  } as Types.IContext.Value;

  if (process.env.NODE_ENV === 'development') console.log('Payment Form Context:', state);

  return (
    <Context.Provider {...{ value }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;