const get = require('lodash/get');

const stringMask = (value, pattern, defaultValue = '') => {

  if (!pattern || !pattern.length) {
    return value;
  }

  if (!value) {
    return '';
  }

  if (value.length !== pattern.match(/#/g).length) {
    return value;
  }

  let i = 0;
  let v = value.toString();

  return pattern.replace(/#/g, _ => get(v, `[${i++}]`, defaultValue));
};

export default stringMask;