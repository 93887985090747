import 'core-js';
import 'node-remove-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import config from './config';

import 'services/i18n';
import 'services/moment';
import { sentry } from 'services';

import App from './App';
// import Snowfall from 'react-snowfall';

ReactDOM.render(
  <>
    {/* <Snowfall
      style={{
        position: 'fixed',
        width: '100vw',
        height: "100vh",
        zIndex: 999
      }}
      snowflakeCount={150}
      radius={[1, 5]}
      wind={[0, 7]}
      speed={[1, 6]}
    // images={images}
    /> */}
    <App />
  </>,
  document.getElementById('root')
);

serviceWorker.unregister();

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  sentry.init({
    dsn: config.sentry.dsn,
    environment: config.app.env,
    release: config.app.version
  });
}
