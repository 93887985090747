import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import Context, { initialState } from './context';
import * as Types from './types';

interface IProps extends Types.Props {
  children: React.ReactNode;
}

const Provider: React.FC<IProps> = ({ children }) => {
  const [state, setState] = useState({ ...initialState });
  const history = useHistory();
  const { serviceId } = useParams<{ serviceId: string }>();

  const setCategory = (categoryId: number) => {
    setState((state) => ({ ...state, categoryId, isVisible: true }));
  };

  const setSubCategory = (subCategoryId: number, categoryId?: number) => {
    setState((state) => ({
      ...state,
      subCategoryId,
      categoryId: categoryId || state.categoryId,
      isVisible: (!!categoryId || state.isVisible),
      isSubCategorySelected: !!categoryId
    }));
  };

  const setDocument = (documentId: number, categoryId?: number, subCategoryId?: number) => {
    setState((state) => ({
      ...state,
      categoryId: categoryId || state.categoryId,
      subCategoryId: subCategoryId || state.subCategoryId,
      documentId,
      isVisible: (!!categoryId || state.isVisible),
      isSubCategorySelected: !!subCategoryId,
      isDocumentSelected: !!categoryId
    }));
  };

  const setClose = () => {
    setState((state) => ({ ...state, isVisible: false }));
    if (serviceId) {
      history.replace('/');
    }
  };

  const value = {
    state,
    setState,
    methods: {
      setCategory,
      setSubCategory,
      setDocument,
      setClose
    }
  } as Types.Value;

  if (process.env.NODE_ENV === 'development') console.log('Category Module Single Context:', state);

  return (
    <Context.Provider {...{ value }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;