export enum LIST {
  REQUEST = '@@STATS/LIST/REQUEST',
  SUCCESS = '@@STATS/LIST/SUCCESS',
  FAILURE = '@@STATS/LIST/FAILURE'
}

export enum USER {
  REQUEST = '@@STATS/USER/REQUEST',
  SUCCESS = '@@STATS/USER/SUCCESS',
  FAILURE = '@@STATS/USER/FAILURE'
}
