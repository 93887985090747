import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import * as AuthModule from 'modules/auth';
import * as SystemModule from 'modules/system';
import * as Icon from 'components/Icon';
import * as Menu from 'components/Menu';
import Button from 'components/Button';
import Profile from 'components/Profile';
import * as Dropdown from 'components/Profile/Dropdown';
import Modal from 'components/Modal';
import Settings from './components/Settings';
import Notification from './components/Notification';
import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';
import Accessibility from './components/Accessibility';

import classes from './LayoutMain.module.scss';

interface IProps {
  children: React.ReactNode;
}

const Main: React.FC<IProps> = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { isAuthenticated, profile, company, methods } = AuthModule.Hooks.useAuth();
  const [isSettingsVisible, setSettingsVisible] = useState(0);

  useEffect(() => {
    const content = document.getElementById('content');
    if (content && content.scrollTo) {
      content.scrollTo(0, 0);
    }
  }, [location.pathname, location.search]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.navbar}>
          <div className={classes.navbarInner}>
            <Link to="/" className={cx(classes.logo)}>
              <img
                width={50}
                src={require('assets/images/organizations/ministry-of-justice.svg')}
                alt="ministry-of-justice"
              />
              <h1 className={classes.logoText}>{t('ministry_of_justice')}</h1>
            </Link>
            <MobileMenu onSettings={type => setSettingsVisible(type)} />
            <div className={classes.menu}>
              <Menu.List className={classes.hideOnMobile}>
                <Menu.Item to="/">{t('menu.main')}</Menu.Item>
                {isAuthenticated && <Menu.Item to="/cabinet">{t('menu.my_cabinet')}</Menu.Item>}
                <Menu.Item to="/registry">{t('menu.registry')}</Menu.Item>
                <Menu.Item to="/organizations">{t('Organizations')}</Menu.Item>
                <Menu.Item to="/help">{t('menu.help')}</Menu.Item>
                {/* <Menu.Item to="https://b-advice.uz/" link>
                  {t('b-advice')}
                </Menu.Item> */}
              </Menu.List>
            </div>
            {isAuthenticated && (
              <div className={classes.button}>
                <Button variant="green" prefixIcon={<Icon.System.Plus />} container={<Link to="/information" />}>
                  {t('submit_application')}
                </Button>
              </div>
            )}
            <div className={classes.rightSide}>
              <Accessibility />
              <SystemModule.Containers.Language />
              {isAuthenticated && (
                <>
                  <Notification />
                  <div className={classes.profile}>
                    <Profile
                      className="m-r-8 m-l-8"
                      name={profile.titleName}
                      company={company.name}
                      dropdown={
                        <Dropdown.List>
                          <Dropdown.Item
                            title={t('My_applications')}
                            icon={<Icon.System.Plus />}
                            container={<Link to="/cabinet" />}
                          />
                          <Dropdown.Item
                            title={t('My_documents')}
                            icon={<Icon.System.Docs />}
                            container={<Link to="/cabinet" />}
                          />
                          {profile.type !== AuthModule.Constants.TYPE.INDIVIDUAL && (
                            <Dropdown.Item
                              title={t('My_organization')}
                              icon={<Icon.System.Company />}
                              onClick={() => setSettingsVisible(2)}
                            />
                          )}
                          <Dropdown.Item
                            title={t('My_details')}
                            icon={<Icon.System.User />}
                            onClick={() => setSettingsVisible(1)}
                          />
                          <Dropdown.Item
                            title={t('Statistics')}
                            icon={<Icon.System.ChartBar />}
                            container={<Link to="/statistics/user" />}
                          />
                          <Dropdown.Item
                            title={t('Logout')}
                            icon={<Icon.System.Logout />}
                            onClick={() => methods.logout()}
                          />
                        </Dropdown.List>
                      }
                    />
                  </div>
                </>
              )}
              {!isAuthenticated && (
                <Button
                  variant="blue"
                  className="m-l-24 m-r-24"
                  prefixIcon={<Icon.System.User />}
                  container={<Link to="/auth" />}
                >
                  {t('login_or_create_account')}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div id="content" className={classes.content}>
          <div className={classes.contentInner}>{children}</div>
          <Footer />
        </div>
      </div>

      <Modal size="small" isOpen={!!isSettingsVisible} close={() => setSettingsVisible(0)}>
        <Settings initialTab={isSettingsVisible} close={() => setSettingsVisible(0)} />
      </Modal>
    </>
  );
};

export default Main;
