import React, { useState } from 'react';

import Context, { initialState } from './context';

import * as Types from './types';

interface IProps extends Types.Props {
  children: React.ReactNode;
}

const Provider: React.FC<IProps> = ({ children }) => {
  const [state, setState] = useState({ ...initialState });

  const setVisible = (id: number) => {
    setState((state) => ({ ...state, id, isVisible: true }));
  };

  const setClose = () => {
    setState((state) => ({ ...state, isVisible: false }));
  };

  const value = {
    state,
    setState,
    methods: {
      setVisible,
      setClose
    }
  } as Types.Value;

  if (process.env.NODE_ENV === 'development') console.log('Category Module Single Context:', state);

  return (
    <Context.Provider {...{ value }}>
      {children}
    </Context.Provider>
  );
};

export default Provider;