export const ENTITIES = '@@CATEGORY/ENTITIES';

export enum LIST {
  REQUEST = '@@CATEGORY/LIST/REQUEST',
  SUCCESS = '@@CATEGORY/LIST/SUCCESS',
  FAILURE = '@@CATEGORY/LIST/FAILURE'
}

export enum SINGLE {
  REQUEST = '@@CATEGORY/SINGLE/REQUEST',
  SUCCESS = '@@CATEGORY/SINGLE/SUCCESS',
  FAILURE = '@@CATEGORY/SINGLE/FAILURE'
}