import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import times from 'lodash/times';
import moment from 'moment';

import * as Types from './types';

export const days = (items: Types.IApi.Day[]): Types.IEntity.Day[] => {
  let daysInMonth: number[] = [];
  const monthDate = moment().startOf('month');
  times(monthDate.daysInMonth(), function(n) {
    daysInMonth = [...daysInMonth, monthDate.unix()];
    monthDate.add(1, 'day');
  });
  items = orderBy(items, ['day'], ['asc']);
  return daysInMonth.map((date, i) => day(get(items, `${i}`), i + 1, date));
};

export const day = (item: Types.IApi.Day, day: number, date: number): Types.IEntity.Day => ({
  statuses: statuses(get(item, 'statuses', null)),
  date,
  day
});

export const statuses = (item: Types.IApi.Status | null): Types.IEntity.Status => {
  item = item || {};
  return {
    all: get(item, 'all') || 0,
    in_review: get(item, 'in_review') || 0,
    completed: get(item, 'completed') || 0,
    denied: get(item, 'denied') || 0
  };
};

export const category = (item: Types.IApi.Category): Types.IEntity.Category => ({
  category: {
    id: get(item, 'id'),
    title: langStrings(get(item, 'category.title')),
    icon: get(item, 'category.icon_id'),
    gradient: {
      start: get(item, 'category.gradient_start_color'),
      end: get(item, 'category.gradient_end_color')
    }
  },
  count: get(item, 'count', 0) || 0,
  percent: get(item, 'percent', 0) || 0
});

export const user = (item: Types.IApi.User): Types.IEntity.User => {
  return {
    all: get(item, 'ALL') || 0,
    applications_documents: get(item, 'APPLICATIONS_DOCUMENTS') || 0,
    completed: get(item, 'COMPLETED') || 0,
    denied: get(item, 'DENIED') || 0,
    in_payment: get(item, 'IN_PAYMENT') || 0,
    in_process: get(item, 'IN_PROCESS') || 0,
    registers: get(item, 'REGISTERS') || 0,
    review: get(item, 'REVIEW') || 0,
  }
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};