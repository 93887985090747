import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ params, filter, token }: Types.IApi.Registry.Request): AxiosPromise<{ data: { registers: Types.IApi.Registry.Response[] } }> => {

  return http.request.get<{ data: { registers: Types.IApi.Registry.Response[] } }>(`/register/open_source`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    },
    params: {
      ...filter,
      page: params.page - 1,
      size: params.limit
    }
  });
};



export const Single = ({ id, token }): AxiosPromise<{ data: Types.IApi.Registry.Response }> => {
  return http.request.get<{ data: Types.IApi.Registry.Response }>(`/register/open_source/${id}`, {
    headers: {
      ...(token ? { 'captcha-response': token } : null)
    }
  });
};