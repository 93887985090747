import { takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import Schema from './schema';
import * as Mappers from './mappers';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { updated_date, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { documents: Types.IApi.Document.Response[], updated_date: number; } }> = yield call(Api.List, { updated_date });
    const items = Mappers.documentList(get(data, 'data.documents') || []);
    const itemIdsByCategoryId = Mappers.documentIdsByCategoryId(get(data, 'data.documents') || []);
    const { entities, result }: NormalizedSchema<{ document: Types.IEntities }, number[]> = normalize(items, [Schema]);
    yield put(Actions.Entities({ items: entities?.document }));
    yield put(Actions.List.success({ updated_date: (get(data, 'data.updated_date')) || 0, ids: result, idsByCategoryId: itemIdsByCategoryId }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.List.failure({ error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, errorMessage);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* ListByCategory(action: ReturnType<typeof Actions.ListByCategory.request>) {
  const { categoryId, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: { documents: Types.IApi.Document.Response[] } }> = yield call(Api.ListByCategory, { categoryId });
    const items = Mappers.documentList(get(data, 'data.documents') || []);
    const { entities, result }: NormalizedSchema<{ document: Types.IEntities }, number[]> = normalize(items, [Schema]);
    yield put(Actions.Entities({ items: entities?.document }));
    yield put(Actions.ListByCategory.success({ categoryId, ids: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.ListByCategory.failure({ categoryId, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, errorMessage);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Info(action: ReturnType<typeof Actions.Info.request>) {
  const { documentId, callback } = action.payload;
  try {
    const { data }: AxiosResponse<{ data: Types.IApi.Info.Response }> = yield call(Api.Info, { documentId });
    const info = Mappers.info(get(data, 'data'));
    yield put(Actions.Info.success({ documentId, info }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { documentId, info });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Info.failure({ documentId, error: errorMessage }));
    if (callback?.onError) {
      yield call(callback.onError, errorMessage);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List),
    takeLatest(Constants.LIST_BY_CATEGORY.REQUEST, ListByCategory),
    takeLatest(Constants.INFO.REQUEST, Info)
  ]);
}