import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';

export const List = ({ updated_date = 0 }: { updated_date: number; }): AxiosPromise<{ documents: Types.IApi.Document.Response[] }> => {
  return http.request.get<{ documents: Types.IApi.Document.Response[] }>(`/reference/documents/all?updated_date=${updated_date}`);
};

export const ListByCategory = ({ categoryId }: { categoryId: number }): AxiosPromise<{ documents: Types.IApi.Document.Response[] }> => {
  return http.request.get<{ documents: Types.IApi.Document.Response[] }>(`/reference/documents?category_id=${categoryId}`);
};

export const Info = ({ documentId }: Types.IApi.Info.Request): AxiosPromise<Types.IApi.Info.Response> => {
  return http.request.get<Types.IApi.Info.Response>(`/reference/documents/info?document_id=${documentId}`);
};