import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as Actions from './actions';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Services from './services';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { callback } = action.payload || {};
  try {
    yield call(CheckVersion);
    yield call(InstallApiKeys);
    const items = yield call(PfxList);
    yield put(Actions.List.success({ items }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    yield put(Actions.List.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, error);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* CheckVersion() {
  const { major, minor } = yield call(Services.request, { name: 'version' });
  console.log(major);
  console.log(minor);
  // throw 'Not supported version';
}

export function* InstallApiKeys() {
  yield call(Services.request, { name: 'apikey', arguments: Constants.API_KEYS });
}

export function* PfxList() {
  const { certificates }: { certificates: Types.IApi.File[]; } = yield call(Services.request, {
    plugin: 'pfx',
    name: 'list_all_certificates'
  });

  const list = certificates.reduce((prev, file) => {
    const alias = file.alias.toUpperCase();
    const values: Types.IApi.Certificate = alias.split(',').reduce((prev, curr) => {
      const [key, value] = curr.split('=');
      if (!value) {
        return ({ ...prev });
      }
      return ({ ...prev, [key]: value });
    }, {} as Types.IApi.Certificate);
    if (!Object.keys(values).length) {
      return [...prev];
    }
    const certificate = { values, file };
    return [...prev, certificate];
  }, []);

  return yield all(list.map(({ values, file }) => {
    return call(PreparePfxList, values, file);
  }));
}

export function* PreparePfxList(certificate: Types.IApi.Certificate, file: Types.IApi.File) {
  const id = yield call(LoadKey, file);
  return Mappers.certificate(id, certificate, file);
}

export function* LoadKey(file: Types.IApi.File) {
  const { keyId } = yield call(Services.request, {
    plugin: 'pfx',
    name: 'load_key',
    arguments: [file.disk, file.path, file.name, file.alias]
  });
  return keyId;
}

export function* CreateKey(id) {
  const { pkcs7_64 }: Types.IEntity.Pkcs7 = yield call(Services.request, { plugin: 'pkcs7', name: 'create_pkcs7', arguments: [btoa('tst'), id, 'no'] });
  return pkcs7_64;
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List)
  ]);
}