import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';

import * as Types from './types';

export const registry = (item: Types.IApi.Registry.Response): Types.IEntity.Registry => {
  const specializations = (get(item, `specializations`) || []).map(s => ({
    id: get(s, 'id') || 0,
    position: get(s, 'position') || 0,
    name: langStrings(get(s, 'name')),
    description: langStrings(get(s, 'description'))
  }));

  const specializationsByPosition = groupBy(specializations, 'position');

  const specializationsByAddress = (get(item, 'activity_addresses') || []).map(item => ({
    name: langStrings(get(item, 'value')),
    specializations: specializationsByPosition[get(item, 'position') || 0] || []
  }));

  return {
    id: get(item, 'uuid') || '',
    tin: get(item, 'tin') ? `${get(item, 'tin')}` : '',
    pin: get(item, 'pin') ? `${get(item, 'pin')}` : '',
    name: get(item, 'name') || '',
    branch: get(item, 'branch') || false,
    number: get(item, 'number') || '',
    organization: langStrings(get(item, 'organization')),
    selected_category: langStrings(get(item, 'selected_category')),
    category: {
      title: langStrings(get(item, 'category.title')),
      image: get(item, 'category.icon_id') || '',
      gradient: {
        start: get(item, 'category.gradient_start_color') || '',
        end: get(item, 'category.gradient_end_color') || ''
      }
    },
    status: {
      key: get(item, 'status.status') || '',
      title: langStrings(get(item, 'status.title'))
    },
    document: langStrings(get(item, 'document')),
    type: {
      key: get(item, 'type') || '',
      title: langStrings(get(item, 'type_title'))
    },
    address: get(item, 'address') || '',
    region: langStrings(get(item, 'region')),
    subRegion: langStrings(get(item, 'subRegion')),
    registration: {
      serial: get(item, 'serial') || '',
      number: get(item, 'register_number') || '',
      date: get(item, 'registration_date') || ''
    },
    expires: get(item, 'expiry_date') || '',
    isActive: !!get(item, 'active'),
    specializations: (get(item, 'specializations') || []).map(item => ({
      id: get(item, 'id') || 0,
      position: get(item, 'position') || 0,
      name: langStrings(get(item, 'name')),
      description: langStrings(get(item, 'description'))
    })),
    specializationsByAddress,
    is_multi_specialization: !!get(item, 'is_multi_specialization'),
    training_categories: get(item, 'training_categories')
      ? (get(item, 'training_categories') || []).map(category => ({
          name: get(category, `name`) || '',
          courses: (get(category, 'courses') || []).map(course => get(course, `name`) || '')
        }))
      : [],
    activityAddresses: (get(item, 'activity_addresses') || []).map(item => langStrings(get(item, 'value'))),
    history: (get(item, 'history') || []).map(item => historyItem(item)),
    feeDate: {
      begin: get(item, 'fees_period_begin') || '',
      end: get(item, 'fees_period_end') || ''
    },
    suspension: {
      begin: get(item, 'suspension_begin') || '',
      end: get(item, 'suspension_end') || ''
    },
    isArchive: !!get(item, 'import_register'),
    brand_mark: get(item, 'brand_mark') || '',
    additions: additions(get(item, 'additions'))
  };
};

export const historyItem = (item?: Types.IApi.HistoryItem): Types.IEntity.HistoryItem => {
  return {
    id: get(item, 'uuid') || '',
    number: get(item, 'number') || '',
    register_number: get(item, 'register_number') || '',
    register_date: get(item, 'registration_date') || '',
    expire_date: get(item, 'expire_date') || '',
    isActive: !!get(item, 'active'),
    status: {
      key: get(item, 'status.status') || '',
      title: langStrings(get(item, 'status.title'))
    }
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};

export const additions = item => {
  return orderBy(
    (item || []).map(item => {
      let value = get(item, 'value') || '';

      return {
        key: get(item, 'key') || '',
        type: get(item, 'type') || '',
        title: get(item, `title`) || '',
        position: get(item, `position`) || '',
        value,
        duplicable_values: groupBy(
          (get(item, 'duplicable_values') || []).map(item => {
            return {
              row: get(item, 'position'),
              position: get(item, 'row'),
              value: get(item, 'value') || '',
              option: get(item, `option`) || '',
              isHead: Number(get(item, 'position')) === 0
            };
          }),
          'row'
        )
      };
    }),
    ['position'],
    'asc'
  );
};
