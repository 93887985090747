import { useState } from 'react';

import config from 'config';

interface IAgrs {
  onSuccess?: (token: any) => void;
}

const useGoogleCaptcha = () => {
  const { key } = config.captcha;

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleLoad = ({ onSuccess }: IAgrs) => {
    if ('grecaptcha' in window) {
      setLoading(true);
      // @ts-ignore
      window.grecaptcha.ready(() => {
        // @ts-ignore
        window.grecaptcha.execute(key).then(something => {
          onSuccess && onSuccess(something);
          setLoading(false);
        })
      });
    }
  };

  return {
    handleLoad,
    isLoading
  };
};

export default useGoogleCaptcha;
