import React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IMaskInput } from 'react-imask';

import * as Types from '../types';

import Field from '../Field';

import classes from '../Field.module.scss';

export enum MASK_TYPE {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

interface IProps extends Types.FieldProps {
  mask: string | any;
  unmask?: boolean;
  type?: MASK_TYPE | string;
  lazy?: boolean;
  nextElementId?: string;
  onChange?: (value: string) => void;
  onComplete?: () => void;
}

const maskType = (type?: MASK_TYPE | string) => {
  switch (type) {
    case MASK_TYPE.NUMBER:
      return /^\d*\.?\d*$/;
    case MASK_TYPE.TEXT:
    default:
      return /^\w+$/;
  }
};

const Mask: React.FC<IProps> = ({
  placeholder,
  lazy = false,
  mask,
  type = MASK_TYPE.TEXT,
  unmask = true,
  showError = true,
  disabled,
  onChange,
  onComplete,
  nextElementId,
  readOnly,
  ...props
}) => {
  const [field, meta, helpers] = useField(props as FieldHookConfig<any>);
  const hasError = !!(showError && meta.touched && meta.error);

  return (
    <Field {...{ hasError, showError, error: meta.error, disabled, readOnly, ...props }}>
      {({ onFocus, onBlur }) => (
        <IMaskInput
          {...{ placeholder, lazy, mask, unmask, onFocus, disabled, readOnly }}
          id={field.name}
          name={field.name}
          value={String(field.value || '') || ''}
          type={type === MASK_TYPE.NUMBER ? 'tel' : 'text'}
          onBlur={e => {
            field.onBlur(e);
            onBlur();
          }}
          onAccept={value => {
            helpers.setValue(value);
            onChange && onChange(value);
          }}
          onComplete={() => {
            onComplete && onComplete();
            if (nextElementId) {
              const nextInput = document.getElementById(nextElementId);
              nextInput && nextInput.focus();
            }
          }}
          className={classes.input}
          definitions={{ '#': maskType(type) }}
          placeholderChar="_"
          overwrite
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      )}
    </Field>
  );
};

export default Mask;
