const ENV = process.env;

const config = {
  app: {
    env: ENV.REACT_APP_ENV,
    version: ENV.REACT_APP_VERSION,
    isBeta: !!ENV.REACT_APP_IS_BETA,
    isDev: ENV.REACT_APP_ENV !== 'production'
  },
  api: {
    base_url: ENV.REACT_APP_API_BASE_URL,
    base_url_v2: ENV.REACT_APP_API_BASE_URL_V2,
    access_token_key: 'access-token',
    refresh_token_key: 'refresh-token'
  },
  document: {
    qr_url: ENV.REACT_APP_QR_BASE_URL,
    base_url: ENV.REACT_APP_DOCUMENT_API_BASE_URL
  },
  sso: {
    link: ENV.REACT_APP_SSO_LINK
  },
  firebase: {
    appId: ENV.REACT_APP_FIREBASE_APP_ID,
    apiKey: ENV.REACT_APP_FIREBASE_KEY,
    projectId: ENV.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: ENV.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    vapidKey: ENV.REACT_APP_FIREBASE_VAPID_KEY
  },
  apps: {
    android: 'https://play.google.com/store/apps/details?id=uz.maroqand.license',
    ios: 'https://apps.apple.com/us/app/licenses-uz/id1545967616'
  },
  sentry: {
    dsn: ENV.REACT_APP_SENTRY_DSN
  },
  captcha: {
    key: ENV.REACT_APP_GOOGLE_CAPTCHA_KEY
  }
};

export default config;
