import { takeLatest, call, put, all } from 'redux-saga/effects';
import { normalize, NormalizedSchema } from 'normalizr';
import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import Schema from './schema';
import * as Mappers from './mappers';
import * as Types from './types';

export function* List(action: ReturnType<typeof Actions.List.request>) {
  const { callback } = action.payload || {};
  try {
    const { data }: AxiosResponse<{ data: { categories: Types.IApi.Category.Response[] } }> = yield call(Api.List);
    const items = (get(data, 'data.categories') || []).map(category => Mappers.category(category));
    const { entities, result }: NormalizedSchema<{ category: Types.IEntities }, number[]> = normalize(items, [Schema]);
    yield put(Actions.Entities({ items: entities?.category }));
    yield put(Actions.List.success({ ids: result }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { items });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorData = errorResponse?.response?.data?.message;
    yield put(Actions.List.failure({ error: errorData }));
    if (callback?.onError) {
      yield call(callback.onError, errorData);
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.LIST.REQUEST, List)
  ]);
}