export enum TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  EMAIL = 'EMAIL',
}

export enum LOGIN {
  REQUEST = '@@AUTH/LOGIN/REQUEST',
  SUCCESS = '@@AUTH/LOGIN/SUCCESS',
  FAILURE = '@@AUTH/LOGIN/FAILURE',
}

export enum LOGIN_CHANGE_TOKEN {
  REQUEST = '@@AUTH/LOGIN_CHANGE_TOKEN/REQUEST',
  SUCCESS = '@@AUTH/LOGIN_CHANGE_TOKEN/SUCCESS',
  FAILURE = '@@AUTH/LOGIN_CHANGE_TOKEN/FAILURE',
}


export enum LOGIN_VIA_DS {
  REQUEST = '@@AUTH/LOGIN_VIA_DS/REQUEST',
  SUCCESS = '@@AUTH/LOGIN_VIA_DS/SUCCESS',
  FAILURE = '@@AUTH/LOGIN_VIA_DS/FAILURE',
}

export enum LOGIN_VIA_EMAIL {
  REQUEST = '@@AUTH/LOGIN_VIA_EMAIL/REQUEST',
  SUCCESS = '@@AUTH/LOGIN_VIA_EMAIL/SUCCESS',
  FAILURE = '@@AUTH/LOGIN_VIA_EMAIL/FAILURE',
}

export enum REGISTER_VIA_EMAIL {
  REQUEST = '@@AUTH/REGISTER_VIA_EMAIL/REQUEST',
  SUCCESS = '@@AUTH/REGISTER_VIA_EMAIL/SUCCESS',
  FAILURE = '@@AUTH/REGISTER_VIA_EMAIL/FAILURE',
}

export enum EMAIL_VERIFICATION {
  REQUEST = '@@AUTH/EMAIL_VERIFICATION/REQUEST',
  SUCCESS = '@@AUTH/EMAIL_VERIFICATION/SUCCESS',
  FAILURE = '@@AUTH/EMAIL_VERIFICATION/FAILURE',
}

export enum LOGOUT {
  REQUEST = '@@AUTH/LOGOUT/REQUEST',
  SUCCESS = '@@AUTH/LOGOUT/SUCCESS'
}

export enum PROFILE {
  REQUEST = '@@AUTH/PROFILE/REQUEST',
  SUCCESS = '@@AUTH/PROFILE/SUCCESS',
  FAILURE = '@@AUTH/PROFILE/FAILURE',
}

export const STORE_RESET = '@@STORE/RESET';
