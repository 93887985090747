import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import get from 'lodash/get';
import * as Types from 'modules/certificate/types';
import * as DocumentModule from 'modules/document';
import * as CertificateModule from 'modules/certificate';
import * as ProcessModule from 'modules/process';
import * as SystemModule from 'modules/system';

import Button from 'components/Button';
import * as Icon from 'components/Icon';

import Payment from './Payment';

interface IProps {
  id: number;
  item: Types.IEntity.Certificate;
  licenseId: string;
  status: CertificateModule.Constants.STATUS;
  type: DocumentModule.Constants.TYPE | string;
  hasUsageFee: boolean;
  feeDateEnd: string;
  isArchive: boolean;
}

const ProcessButtons: React.FC<IProps> = ({
  id,
  item,
  licenseId,
  status,
  type,
  hasUsageFee,
  feeDateEnd,
  isArchive
}) => {
  const { t } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingBranch, setBranchLoading] = useState(false);

  const [loadingType, setLoadingType] = useState('');
  const Process = ProcessModule.Hooks.useProcessStart({ registerId: id });

  const STATUS = CertificateModule.Constants.STATUS;
  const PROCESS_TYPES = ProcessModule.Constants.TYPE;
  const DOCUMENT_TYPES = DocumentModule.Constants.TYPE;

  const onClick = (type: ProcessModule.Constants.TYPE) => {
    if (!isLoading) {
      setLoading(true);
      setLoadingType(type);
      Process.start(type, {
        onSuccess: () => {},
        onError: error => {
          const message = get(error, `message.title[${language}]`) || '';
          const type = get(error, `message.type`) || '';
          !!message && (type === 'INFO' ? toast(message) : toast.error(message));
        },
        onFinally: () => {
          setLoading(false);
        }
      });
    }
  };

  const isTrue = useMemo(() => {
    if (status === STATUS.NOT_PAID) {
      if ([136, 138, 4603].includes(item.document_id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, []);

  const onClickBranch = () => {
    if (!isLoadingBranch) {
      setBranchLoading(true);
      Process.branch(PROCESS_TYPES.REGISTRATION, {
        onSuccess: () => {},
        onError: (error: any) => {
          const message = get(error, `message.title[${language}]`) || '';
          const type = get(error, `message.type`) || '';
          !!message && (type === 'INFO' ? toast(message) : toast.error(message));
        },
        onFinally: () => {
          setBranchLoading(false);
        }
      });
    }
  };

  const buttons = [
    {
      key: 'active',
      component: (
        <Button
          className="m-r-12"
          variant="green"
          prefixIcon={<Icon.System.Check />}
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.SUSPEND_TO_ACTIVE}
          onClick={() => onClick(PROCESS_TYPES.SUSPEND_TO_ACTIVE)}
        >
          {t('suspend_to_active')}
        </Button>
      ),
      statuses: [STATUS.SUSPENDED],
      type: [
        DOCUMENT_TYPES.LICENSE,
        DOCUMENT_TYPES.PERMIT,
        DOCUMENT_TYPES.NOTIFICATION,
        DOCUMENT_TYPES.CERTIFICATE,
        DOCUMENT_TYPES.CONFIRMATION
      ],
      isVisible: true
    },
    {
      key: 'new',
      component: (
        <Button disabled={isLoading} prefixIcon={<Icon.System.Edit />} className="m-r-12">
          {t('submit_new_application')}
        </Button>
      ),
      statuses: [STATUS.CANCELED, STATUS.EXPIRED, STATUS.ACTIVE],
      type: [DOCUMENT_TYPES.LICENSE, DOCUMENT_TYPES.PERMIT, DOCUMENT_TYPES.NOTIFICATION],
      isVisible: true
    },
    // {
    //   key: 'resume',
    //   component: (
    //     <Button variant="stroke" prefixIcon={<Icon.System.Play/>} className="m-r-12">Возобновить</Button>
    //   ),
    //   statuses: [STATUS.SUSPENDED]
    // },
    // {
    //   key: 'suspend',
    //   component: (
    //     <Button variant="stroke" prefixIcon={<Icon.System.Pause/>} className="m-r-12">Приостановить</Button>
    //   ),
    //   statuses: [STATUS.ACTIVE]
    // },
    {
      key: 'renew',
      component: (
        <Button
          className="m-r-12"
          prefixIcon={<Icon.System.Edit />}
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.CHANGE}
          onClick={() => onClick(PROCESS_TYPES.CHANGE)}
        >
          {t('Renewal')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE, STATUS.SUSPENDED, STATUS.EXPIRED],
      type: [
        DOCUMENT_TYPES.LICENSE,
        DOCUMENT_TYPES.PERMIT,
        DOCUMENT_TYPES.NOTIFICATION,
        DOCUMENT_TYPES.CERTIFICATE,
        DOCUMENT_TYPES.CONFIRMATION
      ],
      isVisible: true
    },
    {
      key: 'modif',
      component: (
        <Button
          className="m-r-12"
          prefixIcon={<Icon.System.Edit />}
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.MODIFICATION}
          onClick={() => onClick(PROCESS_TYPES.MODIFICATION)}
        >
          {t('Modification')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE, STATUS.SUSPENDED, STATUS.EXPIRED],
      type: [DOCUMENT_TYPES.LICENSE, DOCUMENT_TYPES.PERMIT, DOCUMENT_TYPES.NOTIFICATION, DOCUMENT_TYPES.CERTIFICATE],
      isVisible: true
    },
    {
      key: 'extend',
      component: (
        <Button
          className="m-r-12"
          prefixIcon={<Icon.System.Time />}
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.EXTENSION}
          onClick={() => onClick(PROCESS_TYPES.EXTENSION)}
        >
          {t('extend_the_deadline')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE],
      type: [DOCUMENT_TYPES.LICENSE, DOCUMENT_TYPES.PERMIT, DOCUMENT_TYPES.CONFIRMATION],
      isVisible: true
    },
    {
      key: 'Suspension',
      component: (
        <Button
          variant="red"
          className="m-r-12"
          prefixIcon={<Icon.System.Cross />}
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.SUSPEND}
          onClick={() => onClick(PROCESS_TYPES.SUSPEND)}
        >
          {t('Suspension')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE, STATUS.SUSPENDED, STATUS.EXPIRED],
      type: [DOCUMENT_TYPES.LICENSE, DOCUMENT_TYPES.PERMIT, DOCUMENT_TYPES.NOTIFICATION],
      isVisible: true
    },
    {
      key: 'cancel',
      component: (
        <Button
          variant="red"
          prefixIcon={<Icon.System.Cross />}
          className="m-r-12"
          disabled={isLoading}
          isLoading={isLoading && loadingType === PROCESS_TYPES.TERMINATION}
          onClick={() => onClick(PROCESS_TYPES.TERMINATION)}
        >
          {t('Termination')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE, STATUS.SUSPENDED, STATUS.NOT_PAID],
      type: [
        DOCUMENT_TYPES.CERTIFICATE,
        DOCUMENT_TYPES.LICENSE,
        DOCUMENT_TYPES.PERMIT,
        DOCUMENT_TYPES.NOTIFICATION,
        DOCUMENT_TYPES.CONFIRMATION
      ],
      isVisible: isTrue
    },
    {
      key: 'add_branch',
      component: (
        <Button isLoading={isLoadingBranch} onClick={onClickBranch} variant="blue" prefixIcon={<Icon.System.Plus />}>
          {t('add_branche')}
        </Button>
      ),
      statuses: [STATUS.ACTIVE, STATUS.SUSPENDED, STATUS.EXPIRED],
      type: [DOCUMENT_TYPES.LICENSE, DOCUMENT_TYPES.PERMIT, DOCUMENT_TYPES.NOTIFICATION],
      isVisible: item.branch
    }
  ];

  return (
    <>
      {hasUsageFee && <Payment {...{ id, licenseId, feeDateEnd }} />}
      {buttons.map(
        button =>
          button.statuses.includes(status) &&
          button.type.includes(type as DocumentModule.Constants.TYPE) &&
          button.isVisible &&
          button.component
      )}
    </>
  );
};

export default ProcessButtons;
